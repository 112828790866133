import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";
import ComparisonTableVS from "../components/competitor/comparison-table-vs";
import CTASection from "../components/misc/cta-section";

const CompetitorvsCompetitor = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const competitor1 = data.comp1;
    const competitor2 = data.comp2;
    const bodyClasses = `${pageContext.type + "s"} vs vs_${competitor1.slug} vs_${competitor2.slug}`;

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            pageHeading={`${competitor1.name} vs. ${competitor2.name} vs. Stitch`}
            pageSubHeading={`ETL software comparison`}
        >
            <Seo
                title={`${competitor1.name} vs. ${competitor2.name} vs. Stitch - Compare features, pricing, services, and more.`}
                description={`${competitor1.name}, ${competitor2.name} and Stitch are all popular ETL tools for data ingestion into cloud data warehouses. This quick guide helps you compare features, pricing, and services.`}
                keywords={[
                    `${competitor1.name}`,
                    `${competitor2.name}`,
                    "Stitch",
                    "product",
                    "comparison",
                    "ETL",
                    "ELT",
                    "data ingestion",
                    `data warehouse`,
                ]}
                slug={"/vs/" + competitor1.slug}
                bodyClass={bodyClasses}
            />
            <ComparisonTableVS competitor1={competitor1} competitor2={competitor2} />
            <CTASection
                heading={`Give Stitch a try, on us`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight st-section--no-margin`}
            />
        </Layout>
    );
};

export default CompetitorvsCompetitor;

export const query = graphql`
    query($competitor1: String!, $competitor2: String!) {
        comp1: contentfulCompetitor(slug: { eq: $competitor1 }) {
            id
            name
            slug
            dataWarehousesDataLakes
            databaseReplication
            developerTools
            focus
            g2_sat_score
            addNewDataSources
            complianceGovernanceAndSecurityCerts
            dataSharing
            purchaseProcess
            saasSources
            supportSla
            transparentPricing
            vendorLockIn
            about {
                about
            }
            competitorLogo {
                file {
                    url
                }
            }
            dataSourcesAndDestinations {
                dataSourcesAndDestinations
            }
            supportDocumentationAndTraining {
                supportDocumentationAndTraining
            }
            transformations {
                transformations
            }
            pricing {
                pricing
            }
        }
        comp2: contentfulCompetitor(slug: { eq: $competitor2 }) {
            id
            name
            slug
            dataWarehousesDataLakes
            databaseReplication
            developerTools
            focus
            g2_sat_score
            addNewDataSources
            complianceGovernanceAndSecurityCerts
            dataSharing
            purchaseProcess
            saasSources
            supportSla
            transparentPricing
            vendorLockIn
            about {
                about
            }
            competitorLogo {
                file {
                    url
                }
            }
            dataSourcesAndDestinations {
                dataSourcesAndDestinations
            }
            supportDocumentationAndTraining {
                supportDocumentationAndTraining
            }
            transformations {
                transformations
            }
            pricing {
                pricing
            }
        }
    }
`;
